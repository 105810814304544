import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import DateAndAuthor from '../components/formatting/DateAndAuthor';
import WithHeroLayout from '../layouts/WithHeroLayout';
import { device } from '../constants';
import MDX from '../cms/MDX';
import moment from 'moment';

const PostWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-bottom: calc(${props => props.theme.paddings.pd}px * 2.5);

  & img {
    width: 100%;
  }
`;

const PostInformationWrapper = styled.section`
  flex: 1 1 30%;
`;
const PostInformationContainer = styled.section`
  padding: ${props => props.theme.paddings.pd * 1.5}px;
`;
const PostContentWrapper = styled.section`
  flex: 1 0 35%;

  padding-right: ${props => props.theme.paddings.pd * 1.5}px;
  padding-left: ${props => props.theme.paddings.pd * 1.5}px;

  @media screen and ${device.laptop} {
    padding: ${props => props.theme.paddings.pd}px
      ${props => props.theme.paddings.pd * 1.5}px;
  }
`;

const Excerpt = styled.div`
  margin-bottom: ${props => props.theme.paddings.pd}px;
`;

interface FeaturedImgProps {
  bgImg: string;
}
const FeaturedImg = styled.div<FeaturedImgProps>`
  padding-bottom: 100%;
  background-image: url('${props =>
    props.bgImg ? props.bgImg : '/images/design/hero/resources.png'}');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const PaginationWrapper = styled.div`
  margin-top: ${props => props.theme.paddings.pd}px;

  & button {
    background-color: #80225f;
    background-repeat: no-repeat;
    background-position: center;
    height: 50px;
    width: 55px;
  }
`;

interface AnnouncementProps {
  announcement: App.News;
}

export const Announcement: React.FC<AnnouncementProps> = function (props) {
  const { announcement } = props;

  const {
    title,
    featured_image,
    excerpt,
    pub_date,
    dc_creator,
    content,
  } = announcement;

  const formattedDate = useMemo(() => moment(pub_date).format('LL'), [
    pub_date,
  ]);

  return (
    <PostWrapper>
      <PostInformationWrapper>
        <FeaturedImg bgImg={featured_image} />
        <PostInformationContainer>
          <h3>{title}</h3>
          <Excerpt>{excerpt}</Excerpt>

          <DateAndAuthor>
            {formattedDate}
            <br />
            by {dc_creator}
          </DateAndAuthor>
        </PostInformationContainer>
      </PostInformationWrapper>

      <PostContentWrapper>
        <MDX>{content}</MDX>
      </PostContentWrapper>
    </PostWrapper>
  );
};

const AnnouncementTemplate: React.FC = function (props) {
  const { data } = props;
  const announcement = data.file.childMdx.frontmatter;

  const { title } = announcement;

  const theme = useTheme();

  return (
    <WithHeroLayout
      pageTitle={title}
      bgImgSrc={theme.colors.purple}
      color={theme.colors.purple}
    >
      <Announcement announcement={announcement} />
    </WithHeroLayout>
  );
};

export const query = graphql`
  query($absolutePath: String!) {
    file(absolutePath: { eq: $absolutePath }) {
      childMdx {
        body
        frontmatter {
          link
          featured_image
          title
          content
          excerpt
          dc_creator
          pub_date
        }
      }
    }
  }
`;

export default AnnouncementTemplate;
